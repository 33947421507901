.hero {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;


    .scrollDown {
        position: absolute;
        bottom: 2%;
        right: 5%;
        cursor: pointer;
        animation: scrollDown 2s ease-in-out infinite;
    }

    h1 {
        color: #FAEEE8;
        font-size: 70px;
        font-weight: 900;
        margin-top: 20vh;
        text-align: center;
        position: relative;
        z-index: 2;
        max-width: 900px;
        padding: 0 30px;
    }

    .image-container {
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;

        .image {
            height: 100vh;
            width: 100vw;
        }
    }
}

@media screen and (max-width: 768px) {
    .hero {
        h1 {
            font-size: 35px;
        }
    }
}

@keyframes scrollDown {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-30px);
    }
    100% {
        transform: translateY(0);
    }  
}