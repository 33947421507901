.contact-info {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 30px;
    padding-top: 100px;
    padding-bottom: 50px;
    background: linear-gradient(67.1deg, #2B3A39 0%, #53716F 100%);
    color: rgba(250, 238, 232, 1);
    column-gap: 150px;
    align-items: center;

    .map {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h2 {
        margin-bottom: 30px;
        font-size: 30px;
        font-weight: 700;
    }

    h3 {
        margin-bottom: 40px;
        font-size: 30px;
        font-weight: 700;
    }

    ul {
        list-style: none;
    }

    li, p {
        font-size: 30px;
    }

    .horaire {
        margin-bottom: 50px;
    }

    .c-office-overview__map {
        height: 100vh;
        width: 100%;
        max-width: 500px;
        max-height: 500px;
        border-radius: 30px;
    }
}


@media screen and (max-width: 880px) {
    .contact-info {
        flex-direction: column;
        padding: 100px 5vw;
        align-items: center;

        .tel-hor {
            display: flex;
            column-gap: 30px;
        }

        .c-office-overview__map {
            width: 90vw;
            height: 120vw;
            margin: auto;
            margin-bottom: 100px;
            border-radius: 10px;
        }
    }
}

@media screen and (max-width: 668px) {
    .contact-info {
        flex-direction: column;
        padding: 100px 5vw;
        align-items: center;

        .tel-hor {
            display: block;
        }

        .c-office-overview__map {
            width: 90vw;
            height: 120vw;
            margin: auto;
            margin-bottom: 100px;
            border-radius: 10px;
        }
    }
}