.home {
    background-color: #ffe5ff;

    padding-bottom: 100px;
    h2 {
        font-size: 40px;
        margin-bottom: 20px;
        font-weight: 300;
        line-height: 1.25;
    }

    .hero {
        h1 {
            max-width: 1020px!important;
        }
    }

    .informations {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 50px;
        row-gap: 50px;
        flex-wrap: wrap;

        .information {
            height: 300px;
            min-width: 300px;
            width: 300px;
            padding: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 1px #000;
            border-radius: 100%;
            text-align: center;
            font-size: 20px;
        }
    }

    .home-institution {
        min-height: 100vh;
        display: flex;
        padding: 0 30px;
        justify-content: center;
        align-items: center;
        column-gap: 130px;
        background: linear-gradient(67.1deg, #2B3A39 0%, #53716F 100%);
        margin-bottom: 100px;
        color: #FAEEE8;
        padding-top: 30px;
        
        .magnetic-button {
            border: solid 2px #FAEEE8;
        }

        .image-container {
            overflow: hidden;
            height: 525px;
            min-width: 400px;
            width: 525px;
            border-radius: 50px;
            display: flex;
            align-items: center;

            img {
                width: 110%;
            }
        }

        .text {
            max-width: 470px;

            p {
                opacity: 0.5;
            }
        }
    }

    .home-prestation {
        padding-bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 45px;
        
        .items {
            display: flex;
            justify-content: center;
            column-gap: 25px;
        }

        .items-res {
            display: none;
        }

        h2 {
            text-align: center;
        }
    }
}

@media screen and (max-width: 900px) {
    .home {
        .home-institution {
            column-gap: 50px;
        }
    }
}

@media screen and (max-width: 768px) {
    .home {
        .home-institution {
            flex-direction: column;
            row-gap: 40px;
            padding-top: 50px;

            .image-container {
                width: 100%;
                max-width: 400px;
                min-width: auto;
                height: 300px;
                border-radius: 30px;

                img {
                    width: 100%;
                }
            }
        }

        .home-prestation {
            .items-res {
                display: flex;
                justify-content: center;
                flex-direction: column;
                row-gap: 25px;
            }
            .items {
                display: none;
            }
        }
    }
}