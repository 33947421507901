@import '../../Components/Hero/hero';
@import '../../Components/MagneticButton/button';
@import '../../Components/Footer/footer';
@import '../../Components/Navbar/navbar';
@import '../../Pages/Home/home';
@import '../../Components//PagesTransition/pagesTransition';
@import '../../Pages/Contact/contact';
@import '../../Pages/Institution/institution';
@import '../../Pages/Prestations/prestations';
@import '../../Pages/Prestation/prestation';
@import '../../Pages/Produits/produits';
@import '../../Pages/Admin/Authentification/authentification';
@import '../../Pages/Admin/admin';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Pacifico&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

body {
    background-color: #FAEEE8;
    max-width: 1440px;
}

.image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.pasifico {
    font-family: 'Pacifico', cursive;
}