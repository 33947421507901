.prestations {
    .hero {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 100px;
    }

    h1 {margin-top: 0;}

    .presations-all {
        display: flex;
        flex-wrap: wrap;
        row-gap: 25px;
        column-gap: 25px;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-bottom: 100px;
    }


    .prestation-card {
        border-radius: 20px;
        overflow: hidden;
        text-decoration: none;
        cursor: pointer;


        img {
            transition: all 0.3s ease-in-out;
            max-height: 300px;
        }

        .cover {
            transition: all 0.3s ease-in-out;
            max-height: 300px;
            object-fit: cover;
            width: 100%;
        }

        &:hover {
            .img-container {
                img {
                    transform: scale(1.1);
                }
            }
            .prestation-card-bottom {
                img {
                    transform: translateX(10px);
                }
            }
        }

        .img-container {
            overflow: hidden;
            height: fit-content;

            img {
                display: block;
            }
        }

        .prestation-card-bottom {
            background-color: #53716F;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 30px;
            column-gap: 30px;

            p {
                color: #fff;         
            }

            img {
                transform: rotate(-90deg);
                height: 15px;
                display: block;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .prestations {
        .prestation-card {
            width: 90%;

            img {
                width: 100%;
            }
        }
    }
}