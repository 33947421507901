footer {
    min-height: 420px;
    width: 100%;
    padding: 0 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000;
    color: #fff;

    a {
        color: #fff;
        font-size: 50px;
    }

    p {
        font-size: 20px;
        opacity: 0.5;
        white-space: nowrap;
    }

    .flex {
        display: flex;
        row-gap: 50px;
        column-gap: 50px;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
    }
}

@media screen and (max-width: 768px) {
    footer {
        align-items: center;
        padding: 0 20px;
        background-position: left;

        p {
            font-size: 15px;
        }

        a {
            font-size: 25px;
            text-align: center;
        }
    }
}