@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Pacifico&display=swap");
.hero {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.hero .scrollDown {
  position: absolute;
  bottom: 2%;
  right: 5%;
  cursor: pointer;
  animation: scrollDown 2s ease-in-out infinite;
}
.hero h1 {
  color: #FAEEE8;
  font-size: 70px;
  font-weight: 900;
  margin-top: 20vh;
  text-align: center;
  position: relative;
  z-index: 2;
  max-width: 900px;
  padding: 0 30px;
}
.hero .image-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}
.hero .image-container .image {
  height: 100vh;
  width: 100vw;
}

@media screen and (max-width: 768px) {
  .hero h1 {
    font-size: 35px;
  }
}
@keyframes scrollDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}
.magnetic-zone .magnetic-button {
  font-size: 16px;
  border: solid 2px #FAEEE8;
  border-radius: 100px;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.magnetic-zone .magnetic-button.secondary {
  border: solid 2px #FAEEE8;
}
.magnetic-zone .magnetic-button.secondary::before {
  background-color: #FAEEE8;
}
.magnetic-zone .magnetic-button.secondary span {
  color: #FAEEE8;
}
.magnetic-zone .magnetic-button a {
  text-decoration: none;
}
.magnetic-zone .magnetic-button span {
  position: relative;
  z-index: 99;
  transition: all 0.7s ease-in-out;
  color: #53716F;
  display: block;
  padding: 10px 30px;
}
.magnetic-zone .magnetic-button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 300%;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  background-color: #FAEEE8;
  transform: translate(-50%, 100%);
  transition: all 0.7s ease-in-out;
}
.magnetic-zone:hover .magnetic-button.secondary span {
  color: #FAEEE8;
}
.magnetic-zone:hover .magnetic-button span {
  color: #53716F !important;
}
.magnetic-zone:hover .magnetic-button::before {
  transform: translate(-50%, -50%);
}

footer {
  min-height: 420px;
  width: 100%;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #000;
  color: #fff;
}
footer a {
  color: #fff;
  font-size: 50px;
}
footer p {
  font-size: 20px;
  opacity: 0.5;
  white-space: nowrap;
}
footer .flex {
  display: flex;
  row-gap: 50px;
  -moz-column-gap: 50px;
       column-gap: 50px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 768px) {
  footer {
    align-items: center;
    padding: 0 20px;
    background-position: left;
  }
  footer p {
    font-size: 15px;
  }
  footer a {
    font-size: 25px;
    text-align: center;
  }
}
.main-navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 50px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.1843137255);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  align-items: center;
}
.main-navbar .logo {
  font-weight: 900;
  color: #FAEEE8;
}
.main-navbar .logo img {
  height: 60px;
}
.main-navbar ul {
  display: flex;
  -moz-column-gap: 50px;
       column-gap: 50px;
}
.main-navbar ul li {
  list-style: none;
}
.main-navbar ul li a {
  color: #FAEEE8;
  text-decoration: none;
}
.main-navbar .menu {
  display: none;
  height: 25px;
  width: 40px;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.main-navbar .menu span {
  display: block;
  height: 2px;
  width: 100%;
  background-color: #FAEEE8;
  position: absolute;
  left: 0;
  top: calc(50% - 1px);
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}
.main-navbar .menu::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #FAEEE8;
  position: absolute;
  left: 0;
  bottom: 0%;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}
.main-navbar .menu::before {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #FAEEE8;
  position: absolute;
  left: 0;
  top: 0%;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}
.main-navbar .menu.active {
  transform: rotate(180deg);
}
.main-navbar .menu.active span {
  opacity: 0;
}
.main-navbar .menu.active::after {
  transform: translate(0, -11.5px) rotate(45deg);
}
.main-navbar .menu.active::before {
  transform: translate(0, 11.5px) rotate(135deg);
}

@media screen and (max-width: 768px) {
  .main-navbar {
    padding: 10px 30px;
  }
  .main-navbar .menu {
    display: block;
  }
  .main-navbar ul {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #53716F;
    flex-direction: column;
    font-size: 40px;
    font-weight: 900;
    align-items: center;
    justify-content: space-around;
    padding: 100px 0;
    transition: all 0.7s cubic-bezier(0.83, -0.01, 0.12, 0.99);
    transform: translateY(-100%);
  }
  .main-navbar ul.active {
    transform: translateY(0);
  }
}
.home {
  background-color: #ffe5ff;
  padding-bottom: 100px;
}
.home h2 {
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 300;
  line-height: 1.25;
}
.home .hero h1 {
  max-width: 1020px !important;
}
.home .informations {
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 50px;
       column-gap: 50px;
  row-gap: 50px;
  flex-wrap: wrap;
}
.home .informations .information {
  height: 300px;
  min-width: 300px;
  width: 300px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #000;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
}
.home .home-institution {
  min-height: 100vh;
  display: flex;
  padding: 0 30px;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 130px;
       column-gap: 130px;
  background: linear-gradient(67.1deg, #2B3A39 0%, #53716F 100%);
  margin-bottom: 100px;
  color: #FAEEE8;
  padding-top: 30px;
}
.home .home-institution .magnetic-button {
  border: solid 2px #FAEEE8;
}
.home .home-institution .image-container {
  overflow: hidden;
  height: 525px;
  min-width: 400px;
  width: 525px;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.home .home-institution .image-container img {
  width: 110%;
}
.home .home-institution .text {
  max-width: 470px;
}
.home .home-institution .text p {
  opacity: 0.5;
}
.home .home-prestation {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 45px;
}
.home .home-prestation .items {
  display: flex;
  justify-content: center;
  -moz-column-gap: 25px;
       column-gap: 25px;
}
.home .home-prestation .items-res {
  display: none;
}
.home .home-prestation h2 {
  text-align: center;
}

@media screen and (max-width: 900px) {
  .home .home-institution {
    -moz-column-gap: 50px;
         column-gap: 50px;
  }
}
@media screen and (max-width: 768px) {
  .home .home-institution {
    flex-direction: column;
    row-gap: 40px;
    padding-top: 50px;
  }
  .home .home-institution .image-container {
    width: 100%;
    max-width: 400px;
    min-width: auto;
    height: 300px;
    border-radius: 30px;
  }
  .home .home-institution .image-container img {
    width: 100%;
  }
  .home .home-prestation .items-res {
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 25px;
  }
  .home .home-prestation .items {
    display: none;
  }
}
.pages-transition {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #FAEEE8;
}

.contact-info {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 30px;
  padding-top: 100px;
  padding-bottom: 50px;
  background: linear-gradient(67.1deg, #2B3A39 0%, #53716F 100%);
  color: rgb(250, 238, 232);
  -moz-column-gap: 150px;
       column-gap: 150px;
  align-items: center;
}
.contact-info .map {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-info h2 {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 700;
}
.contact-info h3 {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 700;
}
.contact-info ul {
  list-style: none;
}
.contact-info li, .contact-info p {
  font-size: 30px;
}
.contact-info .horaire {
  margin-bottom: 50px;
}
.contact-info .c-office-overview__map {
  height: 100vh;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  border-radius: 30px;
}

@media screen and (max-width: 880px) {
  .contact-info {
    flex-direction: column;
    padding: 100px 5vw;
    align-items: center;
  }
  .contact-info .tel-hor {
    display: flex;
    -moz-column-gap: 30px;
         column-gap: 30px;
  }
  .contact-info .c-office-overview__map {
    width: 90vw;
    height: 120vw;
    margin: auto;
    margin-bottom: 100px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 668px) {
  .contact-info {
    flex-direction: column;
    padding: 100px 5vw;
    align-items: center;
  }
  .contact-info .tel-hor {
    display: block;
  }
  .contact-info .c-office-overview__map {
    width: 90vw;
    height: 120vw;
    margin: auto;
    margin-bottom: 100px;
    border-radius: 10px;
  }
}
.institutions .hero {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.institutions .reverse {
  flex-direction: row-reverse;
}
.institutions h2 {
  width: 60%;
  font-size: 40px;
  text-align: center;
  margin: auto;
  line-height: 150%;
  color: #FAEEE8;
}
.institutions .institution-content {
  display: flex;
  padding: 0 30px;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 130px;
       column-gap: 130px;
  margin-bottom: 100px;
}
.institutions .institution-content .image-container {
  overflow: hidden;
  height: 450px;
  min-width: 400px;
  width: 525px;
  border-radius: 30px;
  display: flex;
  align-items: center;
}
.institutions .institution-content .image-container img {
  width: 110%;
}
.institutions .institution-content .image-container-1 {
  height: 380px;
  width: 450px;
}
.institutions .institution-content .text {
  max-width: 470px;
}
.institutions .institution-content .text p {
  font-size: 30px;
  line-height: 150%;
}

@media screen and (max-width: 900px) {
  .institutions h2 {
    width: 80%;
    font-size: 35px;
  }
  .institutions .institution-content {
    -moz-column-gap: 50px;
         column-gap: 50px;
  }
}
@media screen and (max-width: 768px) {
  .institutions h2 {
    width: 80%;
    font-size: 30px;
    margin-bottom: 30px;
  }
  .institutions .institution-content {
    flex-direction: column;
    row-gap: 40px;
    padding-top: 50px;
  }
  .institutions .institution-content .image-container {
    width: 100%;
    max-width: 400px;
    min-width: auto;
    height: 300px;
    border-radius: 30px;
  }
  .institutions .institution-content .image-container img {
    width: 100%;
  }
}
.prestations .hero {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.prestations h1 {
  margin-top: 0;
}
.prestations .presations-all {
  display: flex;
  flex-wrap: wrap;
  row-gap: 25px;
  -moz-column-gap: 25px;
       column-gap: 25px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 100px;
}
.prestations .prestation-card {
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
}
.prestations .prestation-card img {
  transition: all 0.3s ease-in-out;
  max-height: 300px;
}
.prestations .prestation-card .cover {
  transition: all 0.3s ease-in-out;
  max-height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.prestations .prestation-card:hover .img-container img {
  transform: scale(1.1);
}
.prestations .prestation-card:hover .prestation-card-bottom img {
  transform: translateX(10px);
}
.prestations .prestation-card .img-container {
  overflow: hidden;
  height: -moz-fit-content;
  height: fit-content;
}
.prestations .prestation-card .img-container img {
  display: block;
}
.prestations .prestation-card .prestation-card-bottom {
  background-color: #53716F;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  -moz-column-gap: 30px;
       column-gap: 30px;
}
.prestations .prestation-card .prestation-card-bottom p {
  color: #fff;
}
.prestations .prestation-card .prestation-card-bottom img {
  transform: rotate(-90deg);
  height: 15px;
  display: block;
}

@media screen and (max-width: 600px) {
  .prestations .prestation-card {
    width: 90%;
  }
  .prestations .prestation-card img {
    width: 100%;
  }
}
.prestation {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 30px;
  background-color: #ffe5ff;
}
.prestation h1 {
  font-size: 30px;
  font-weight: 900;
}
.prestation h2 {
  font-size: 20px;
  font-weight: 900;
  margin-top: 40px;
  margin-bottom: 20px;
}
.prestation h3 {
  margin-bottom: 10px;
}
.prestation .pres {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  -moz-column-gap: 100px;
       column-gap: 100px;
}
.prestation .paragraph {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 40px;
}
.prestation .presta-description {
  margin-bottom: 50px;
  max-width: 600px;
  font-size: 20px;
}
.prestation .prices {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.prestation p {
  opacity: 0.5;
  max-width: 400px;
}

.produits .produits-content {
  padding: 100px 20px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 120px;
  -moz-column-gap: 50px;
       column-gap: 50px;
  align-items: center;
  justify-content: center;
}
.produits .produits-content .produits {
  width: 100%;
  max-width: 655px;
}
.produits .produits-content .marque {
  width: 100%;
  max-width: 446px;
}

#authentification {
  height: 100vh;
  display: flex;
}
#authentification .left-container, #authentification .image {
  height: 100%;
}
#authentification .left-container {
  width: 50%;
}
#authentification h1 {
  margin-right: auto;
  margin-bottom: 35px;
  font-weight: 900;
  font-size: 50px;
}
#authentification .right-container {
  display: flex;
  padding: 80px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
}
#authentification input {
  padding: 20px 50px;
  border: none;
  background-color: transparent;
  border: solid 3px #000;
  margin-bottom: 20px;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  border-radius: 10px;
}
#authentification button {
  padding: 20px 100px;
  border: none;
  border-radius: 30px;
  background-color: #4c6766;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}

#createPrestation {
  padding: 150px 10vw;
}
#createPrestation header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#createPrestation .back {
  background-color: #000;
  color: #fff;
  padding: 10px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  white-space: nowrap;
}
#createPrestation .createPrestation__container {
  display: flex;
  -moz-column-gap: 50px;
       column-gap: 50px;
  row-gap: 50px;
  flex-wrap: wrap;
}
#createPrestation input, #createPrestation textarea {
  border: none;
  background-color: transparent;
  font-size: 20px;
  outline: none;
}
#createPrestation .imageBox {
  padding-top: 10px;
  padding-bottom: 20px;
  position: relative;
}
#createPrestation .imageBox .img-container {
  height: 300px;
  width: 300px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 20px;
}
#createPrestation .imageBox .img-container .image {
  height: 100%;
  width: 100%;
}
#createPrestation .imageBox button {
  position: absolute;
  border-radius: 20px;
  width: 300px;
  height: 300px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
#createPrestation .imageBox button.border {
  border: solid 7px #000;
}
#createPrestation .imageBox button.border img {
  opacity: 1;
}
#createPrestation .imageBox button:hover img {
  transform: scale(1.1);
}
#createPrestation .imageBox button img {
  margin: auto;
  transition: all 0.3s;
  width: 50px;
  opacity: 0;
}
#createPrestation .delete {
  background-color: red;
  color: #fff;
  padding: 10px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  white-space: nowrap;
}
#createPrestation .add {
  background-color: rgb(117, 218, 117);
  color: #fff;
  padding: 10px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}
#createPrestation .paragraph {
  padding: 10px;
  margin: 100px 0;
  position: relative;
}
#createPrestation .paragraph textarea {
  min-width: 400px;
  max-width: 400px;
  min-height: 30px;
}
#createPrestation .paragraph .info {
  display: flex;
  margin: 50px 0;
  flex-direction: column;
  row-gap: 30px;
}
#createPrestation .paragraph .new-presations {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
#createPrestation .paragraph .new-prestation {
  display: flex;
  -moz-column-gap: 30px;
       column-gap: 30px;
  font-size: 20px;
  position: relative;
}
#createPrestation .paragraph .new-prestation input {
  max-height: 30px;
}
#createPrestation .paragraph .new-prestation textarea, #createPrestation .paragraph .new-prestation input {
  font-size: 17px;
}

#admin-presations {
  padding: 150px 10vw;
}
#admin-presations .admin-presations__container {
  height: 100%;
  width: 100%;
}
#admin-presations .admin-presations__container .more {
  height: 250px;
  width: 250px;
  background-color: transparent;
  border: solid 7px;
  border-radius: 30px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
}
#admin-presations .admin-presations__container .more:hover {
  transform: scale(0.9);
}
#admin-presations .admin-presations__container .more::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 5px;
  width: 50px;
  background-color: #000;
  transform: translate(-50%, -50%);
  border-radius: 100px;
}
#admin-presations .admin-presations__container .more::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 5px;
  width: 50px;
  background-color: #000;
  transform: translate(-50%, -50%) rotate(90deg);
  border-radius: 100px;
}
#admin-presations .prestation-card-container {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
}
#admin-presations .prestation-card-container .button-container {
  position: absolute;
  right: 3%;
  top: 3%;
  z-index: 99;
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
#admin-presations .prestation-card-container .button-container button {
  cursor: pointer;
  color: #fff;
  padding: 10px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  white-space: nowrap;
}
#admin-presations .prestation-card-container .button-container button.edit {
  background-color: rgb(52, 52, 255);
}
#admin-presations .prestation-card-container .button-container button.delete {
  background-color: rgb(255, 52, 52);
}
#admin-presations .presations-all {
  display: flex;
  flex-wrap: wrap;
  row-gap: 25px;
  -moz-column-gap: 25px;
       column-gap: 25px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 100px;
}
#admin-presations .prestation-card {
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
}
#admin-presations .prestation-card img {
  transition: all 0.3s ease-in-out;
  max-height: 300px;
}
#admin-presations .prestation-card:hover .img-container img {
  transform: scale(1.1);
}
#admin-presations .prestation-card:hover .prestation-card-bottom img {
  transform: translateX(10px);
}
#admin-presations .prestation-card .img-container {
  overflow: hidden;
  height: -moz-fit-content;
  height: fit-content;
}
#admin-presations .prestation-card .img-container img {
  display: block;
}
#admin-presations .prestation-card .prestation-card-bottom {
  background-color: #53716F;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  -moz-column-gap: 30px;
       column-gap: 30px;
}
#admin-presations .prestation-card .prestation-card-bottom p {
  color: #fff;
}
#admin-presations .prestation-card .prestation-card-bottom img {
  transform: rotate(-90deg);
  height: 15px;
  display: block;
}

#admin {
  min-height: 100vh;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

body {
  background-color: #FAEEE8;
  max-width: 1440px;
}

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pasifico {
  font-family: "Pacifico", cursive;
}/*# sourceMappingURL=styles.css.map */