.magnetic-zone {
    .magnetic-button {
        font-size: 16px;
        border: solid 2px #FAEEE8;
        border-radius: 100px;
        background-color: transparent;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &.secondary {
            border: solid 2px #FAEEE8;

            &::before {
                background-color: #FAEEE8;
            }

            span {
                color: #FAEEE8;
            }
        }

        a {
            text-decoration: none;
        }

        span {
            position: relative;
            z-index: 99;
            transition: all 0.7s ease-in-out;
            color: #53716F;
            display: block;
            padding: 10px 30px;
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 300%;
            border-radius: 100%;
            left: 50%;
            top: 50%;
            background-color: #FAEEE8;
            transform: translate(-50%, 100%);
            transition: all 0.7s ease-in-out;
        }
    }

    &:hover {
        .magnetic-button {

            &.secondary {
                span {
                    color: #FAEEE8;
                }
            }

            span {
                color: #53716F!important;
            }

            &::before {
                transform: translate(-50%, -50%);
            }
        }
    }
}