.prestation {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 150px 30px;
    background-color: #ffe5ff;

    h1 {
        font-size: 30px;
        font-weight: 900;
    }
    h2 {
        font-size: 20px;
        font-weight: 900;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    h3 {
        margin-bottom: 10px;
    }

    .pres {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        column-gap: 100px;
    }

    .paragraph {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-bottom: 40px;
    }

    .presta-description {
        margin-bottom: 50px;
        max-width: 600px;
        font-size: 20px;
    }

    .prices {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    p {
        opacity: 0.5;
        max-width: 400px;
    }
}