.main-navbar {
    position: fixed;
    display:  flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 50px;
    z-index: 9999;
    background-color: #0000002f;
    backdrop-filter: blur(20px);
    align-items: center;

    .logo {
        font-weight: 900;
        color: #FAEEE8;

        img {
            height: 60px;
        }
    }
    
    ul {
       display: flex;
        column-gap: 50px;
        

        li {
            list-style: none;
            a {
                color: #FAEEE8;
                text-decoration: none;
            }
        }
    }

    .menu {
        display: none;
        height: 25px;
        width: 40px;
        position: relative;
        cursor: pointer;
        transition: all 0.5s ease-in-out;

        span {
            display: block;
            height: 2px;
            width: 100%;
            background-color: #FAEEE8;
            position: absolute;
            left: 0;
            top: calc(50% - 1px);
            border-radius: 20px;   
            transition: all 0.5s ease-in-out;
        }

        &::after {
            content: '';
            height: 2px;
            width: 100%;
            background-color: #FAEEE8;
            position: absolute;
            left: 0;
            bottom: 0%;
            border-radius: 20px;
            transition: all 0.5s ease-in-out;
        }
        &::before {
            content: '';
            height: 2px;
            width: 100%;
            background-color: #FAEEE8;
            position: absolute;
            left: 0;
            top: 0%;
            border-radius: 20px;
            transition: all 0.5s ease-in-out;
        }

        &.active {
            transform: rotate(180deg);
            span {
                opacity: 0;
            }
            &::after {
                transform: translate(0, -11.5px) rotate(45deg);
            }
            &::before {
                
                transform: translate(0, 11.5px) rotate(135deg);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .main-navbar {
        padding: 10px 30px;
        .menu {
            display: block;
        }

        ul {
            position: fixed;
            height: 100vh;
            top: 0;
            left: 0;
            width: 100%;
            background-color: #53716F;
            flex-direction: column;
            font-size: 40px;
            font-weight: 900;
            align-items: center;
            justify-content: space-around;
            padding: 100px 0;
            transition: all 0.7s cubic-bezier(.83,-0.01,.12,.99);
            transform: translateY(-100%);

            &.active {
                transform: translateY(0);
            }
        }
    }
}